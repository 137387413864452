import { useRequestErrorToast } from './useRequestErrorToast'

import { storeToRefs } from 'pinia'

import {
  printAmazonBarcodeLabel,
  printEmployeeBadgeLabel,
  printProductBarcodeLabel,
  printRetailBarcodeLabel,
} from '@/services/blackfisk/printer'

import { usePrinterStore } from '@/stores/printer'

export const usePrinters = () => {
  const PrinterStore = usePrinterStore()
  const { labelConfiguration, refreshPrinters, refreshPapers } = PrinterStore
  const { accountPrinters, papers, labelPrinterMap } = storeToRefs(PrinterStore)
  const refreshPrintersAndPaper = () => {
    // ? Will these run only when needed?
    // * Caching controlled by server header.
    refreshPrinters()
    refreshPapers()
  }
  const printProductBarcode = async (
    serverPrinterId = 0,
    barcode = '',
    sku = '',
    title = ''
  ) => {
    if (
      serverPrinterId > 0 &&
      barcode.length > 0 &&
      sku.length > 0 &&
      title.length > 0
    ) {
      const errors = await printProductBarcodeLabel(
        serverPrinterId,
        barcode,
        sku,
        title
      )
      if (errors?.length ?? 0) {
        useRequestErrorToast(errors, 'Error printing Product Barcode')
      }
    }
  }
  const printRetailBarcode = async (
    serverPrinterId = 0,
    productId = 0,
    channelId = 0
  ) => {
    if (serverPrinterId > 0 && productId > 0 && channelId > 0) {
      const errors = await printRetailBarcodeLabel(
        serverPrinterId,
        productId,
        channelId
      )
      if (errors?.length ?? 0) {
        useRequestErrorToast(errors, 'Error printing Retail Barcode')
      }
    }
  }
  const printEmployeeBadge = async (serverPrinterId = 0, userId = 0) => {
    if (serverPrinterId > 0 && userId > 0) {
      const errors = await printEmployeeBadgeLabel(serverPrinterId, userId)
      if (errors?.length ?? 0) {
        useRequestErrorToast(errors, 'Error printing Retail Barcode')
      }
    }
  }
  const printAmazonBarcode = async (
    serverPrinterId = 0,
    fnsku = '',
    labelSku = '',
    labelTitle = '',
    labelCondition = '',
    quantity = 1
  ) => {
    if (serverPrinterId > 0 && fnsku.length > 0 && quantity > 0) {
      const errors = await printAmazonBarcodeLabel(
        serverPrinterId,
        fnsku,
        labelSku,
        labelTitle,
        labelCondition,
        quantity
      )
      if (errors?.length ?? 0) {
        useRequestErrorToast(errors, 'Error printing Retail Barcode')
      }
    }
  }
  return {
    refreshPrintersAndPaper,
    printProductBarcode,
    printRetailBarcode,
    printEmployeeBadge,
    printAmazonBarcode,
    labelConfiguration,
    accountPrinters,
    papers,
    labelPrinterMap,
  }
}
