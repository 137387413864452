import type { Paper, Printer } from '@/entities/printer/types/printer'
import type { AccountStoreRecord } from '@/stores/types/accountStore'
import type { ShallowRef } from 'vue'

import { computed, shallowRef } from 'vue'
import { defineStore } from 'pinia'

import { paperList, printerList } from '@/services/blackfisk/printer'

import { useRequestErrorToast } from '@/composables/useRequestErrorToast'

import { useIdentityStore } from '@/stores/identity'

export interface PersistedPrinter
  extends Pick<Printer, 'id' | 'serverId' | 'name' | 'commonName' | 'paperId'> {
  serverName: string
}

export interface LabelConfiguration {
  title: string
  paperTypeId: number
  paperIds: number[]
}
export type LabelConfig = Record<LabelType, LabelConfiguration>

export interface PersistedPaper
  extends Pick<
    Paper,
    | 'id'
    | 'code'
    | 'isActive'
    | 'isContinuousFeed'
    | 'isThermalLabel'
    | 'name'
    | 'paperLength'
    | 'paperTypeId'
    | 'paperWidth'
    | 'printDPI'
    | 'printRate'
  > {
  type: string
}
export type LabelType =
  | 'ProductBarcodeLabel'
  | 'RetailBarcodeLabel'
  | 'PguLabel'
  | 'DivisionLabel'
  | 'ShippingLabel'
  | 'LetterPaper'
  | 'Receipts'
export type LabelPrinterMap = Record<LabelType, number>

export const usePrinterStore = defineStore(
  'printer',
  () => {
    const papers: ShallowRef<Record<number, PersistedPaper>> = shallowRef({})
    const printers: ShallowRef<AccountStoreRecord<PersistedPrinter>> =
      shallowRef({})
    const labelPrinterMap: ShallowRef<LabelPrinterMap> = shallowRef({
      ProductBarcodeLabel: 0,
      RetailBarcodeLabel: 0,
      PguLabel: 0,
      DivisionLabel: 0,
      ShippingLabel: 0,
      LetterPaper: 0,
      Receipts: 0,
    })
    const updateLabelPrinterMap = (label: LabelType, printerId = 0) => {
      labelPrinterMap.value = {
        ...labelPrinterMap.value,
        [label]: printerId,
      }
    }

    const labelConfiguration: LabelConfig = {
      ProductBarcodeLabel: {
        title: 'Product Barcode [3" x 1"]',
        paperTypeId: 25,
        paperIds: [32],
      },
      RetailBarcodeLabel: {
        title: 'Retail Product Label [2.25" x 1"]',
        paperTypeId: 25,
        paperIds: [64],
      },
      PguLabel: {
        title: 'PGU Label [4" x 2.5"]',
        paperTypeId: 10,
        paperIds: [12],
      },
      DivisionLabel: {
        title: 'Division Label [Various]',
        paperTypeId: 10,
        paperIds: [],
      },
      ShippingLabel: {
        title: 'Shipping Label [Various]',
        paperTypeId: 20,
        paperIds: [],
      },
      LetterPaper: {
        title: 'Letter [8.5" x 11"]',
        paperTypeId: 0,
        paperIds: [2],
      },
      Receipts: {
        title: 'Receipt [Various]',
        paperTypeId: 5,
        paperIds: [],
      },
    }

    const IdentityStore = useIdentityStore()
    const updateAccountMap = <T extends { id?: number }>(
      updatedRecords: T[],
      map: ShallowRef<AccountStoreRecord<T>>,
      getKey = (updatedRecord: T) => `${updatedRecord?.id ?? 0}`
    ) => {
      const updatedAccountMap = updatedRecords.reduce<AccountStoreRecord<T>>(
        (accumulator, updatedRecord) => {
          if (!accumulator[IdentityStore.accountId]) {
            accumulator[IdentityStore.accountId] = {}
          }
          accumulator[IdentityStore.accountId][getKey(updatedRecord)] =
            updatedRecord
          return accumulator
        },
        {}
      )
      map.value = {
        ...map.value,
        ...updatedAccountMap,
      }
    }
    const updatePrinters = (updatedRecords: PersistedPrinter[]) => {
      updateAccountMap<PersistedPrinter>(
        updatedRecords.map((printer) => ({
          id: printer.id,
          serverId: printer.serverId,
          name: printer.name,
          commonName: printer.commonName,
          paperId: printer.paperId,
          serverName: printer.serverName,
        })),
        printers
      )
    }
    const fetchPrinters = async (forceRefresh = false) => {
      const { servers, errors, cached } = await printerList(forceRefresh)
      if (errors.length > 0) {
        useRequestErrorToast(errors, 'Error listing Printers')
      }
      return { servers, cached }
    }
    const refreshPrinters = async (forceRefresh = false) => {
      // * If authenticated, refresh printers.
      if (IdentityStore.isAuthenticated) {
        const { servers: updatedServers, cached } =
          await fetchPrinters(forceRefresh)
        if (
          updatedServers.length > 0 &&
          ((cached && Object.keys(accountPrinters.value).length === 0) ||
            !cached)
        ) {
          // * Transform ServerGraph[] -> PersistedPrinter[]
          const updatedPrinters = updatedServers.reduce<PersistedPrinter[]>(
            (accumulator, server) => {
              for (const printer of server.printers) {
                accumulator.push({
                  ...printer,
                  serverName: server.commonName,
                })
              }
              return accumulator
            },
            []
          )
          updatePrinters(updatedPrinters)
        }
      }
    }
    const accountPrinters = computed(() => {
      return printers.value?.[IdentityStore.accountId] ?? {}
    })
    const fetchPapers = async (forceRefresh = false) => {
      const { papers, errors, cached } = await paperList(forceRefresh)
      if (errors.length > 0) {
        useRequestErrorToast(errors, 'Error listing Paper')
      }
      return { papers, cached }
    }
    const refreshPapers = async (forceRefresh = false) => {
      if (IdentityStore.isAuthenticated) {
        const { papers: updatedPapers, cached } =
          await fetchPapers(forceRefresh)
        if (
          updatedPapers.length > 0 &&
          ((cached && Object.keys(papers.value).length === 0) || !cached)
        ) {
          // * Transform ServerGraph[] -> PersistedPrinter[]
          papers.value = updatedPapers.reduce<Record<number, PersistedPaper>>(
            (accumulator, paper) => {
              accumulator[paper.id] = {
                id: paper.id,
                code: paper.code,
                isActive: paper.isActive,
                isContinuousFeed: paper.isContinuousFeed,
                isThermalLabel: paper.isThermalLabel,
                name: paper.name,
                paperLength: paper.paperLength,
                paperTypeId: paper.paperTypeId,
                paperWidth: paper.paperWidth,
                printDPI: paper.printDPI,
                printRate: paper.printRate,
                type: paper.type.name,
              }
              return accumulator
            },
            {}
          )
        }
      }
    }
    return {
      papers,
      fetchPapers,
      refreshPapers,
      printers,
      accountPrinters,
      updatePrinters,
      fetchPrinters,
      refreshPrinters,
      labelPrinterMap,
      updateLabelPrinterMap,
      labelConfiguration,
    }
  },
  {
    persist: {
      storage: localStorage,
    },
  }
)
