<script setup lang="ts">
  import { MdBarcodeReader, UnWeight } from '@kalimahapps/vue-icons'
  import { RouterView } from 'vue-router'
  import { computed, onBeforeMount } from 'vue'

  import AppSettingsButton from '@/components/AppSettingsButton.vue'
  import MainNavigation from '@/components/MainNavigation.vue'
  import MemoryUsage from '@/components/MemoryUsage.vue'
  import DynamicDialog from 'primevue/dynamicdialog'
  import Toast from 'primevue/toast'
  import { useAccount } from '@/composables/useAccount'
  import { useAppDrawer } from '@/composables/useAppDrawer'
  import { useLogRocket } from '@/composables/useLogRocket'
  import { usePerformanceProfiling } from '@/composables/usePerformanceProfiling'
  import { usePrinters } from '@/composables/usePrinters'
  import { useQuickLogin } from '@/composables/useQuickLogin'
  import { useScaleWeight } from '@/composables/useScaleWeight'
  import { useScanListener } from '@/composables/useScanListener'
  import { useScanner } from '@/composables/useScanner'
  import { useSentry } from '@/composables/useSentry'

  import { useIdentityStore } from '@/stores/identity'

  const { accountVersion } = useAccount()
  const { registerScanner } = useScanner()
  const { registerListeners, listeners } = useScanListener()
  const {
    isReadingScale,
    updateScale,
    requestScale,
    scaleDevice,
    scaleUnits,
    scaleWeight,
    isScalePaired,
    isScaleOpened,
  } = useScaleWeight()
  onBeforeMount(() => {
    updateScale()
  })
  const connectScale = () => {
    if (!isScalePaired.value && !isScaleOpened.value) {
      requestScale()
    }
    if (isScalePaired.value && !isScaleOpened.value) {
      updateScale()
    }
  }
  const scaleStatusMessage = computed(() => {
    if (isScalePaired.value) {
      if (isReadingScale.value) {
        return `Scale: ${scaleDevice.value?.productName ?? 'Generic Scale'} | ${scaleWeight.value} ${scaleUnits.value}`
      }
      return `Scale: ${scaleDevice.value?.productName ?? 'Generic Scale'}`
    }
    if (!isScalePaired.value && !isScaleOpened.value) {
      return 'Click to Connect a Scale'
    }
    return 'Scale Status Unknown'
  })
  const { showContextDrawer, subscribers: drawerSubscribers } = useAppDrawer()

  useQuickLogin()

  registerScanner()
  registerListeners()

  useLogRocket()

  const { initializeSentryUser } = useSentry()
  initializeSentryUser()

  const IdentityStore = useIdentityStore()

  const { refreshPrintersAndPaper } = usePrinters()
  if (IdentityStore.isAuthenticated) {
    refreshPrintersAndPaper()
  }

  const environment = import.meta.env.VITE_ENVIRONMENT
  const { isMemoryUsageDisplayEnabled } = usePerformanceProfiling()
</script>

<template>
  <div class="bf-background-dark bf-text flex min-h-screen flex-col">
    <DynamicDialog />
    <div class="flex flex-1 flex-col">
      <header class="w-full">
        <MainNavigation />
      </header>
      <Toast position="bottom-right" group="br" class="!z-[1200]" />
      <Toast position="bottom-center" group="bc" class="!z-[1200]" />
      <MemoryUsage
        v-if="environment === 'local' && isMemoryUsageDisplayEnabled"
      />
      <div
        v-auto-animate="{ duration: 100 }"
        class="flex w-full flex-1 flex-col sm:flex-row"
      >
        <div
          v-if="IdentityStore.isAuthenticated"
          class="mt-0 flex border-r border-r-surface-100 bg-surface-50 px-2.5 py-4 pt-6 shadow-sm dark:border-r-surface-750 dark:bg-surface-800 sm:mt-[-0.2rem]"
        >
          <div
            class="flex w-full flex-row items-center justify-between gap-4 sm:flex-col"
          >
            <div class="flex flex-row items-center gap-6 sm:flex-col sm:gap-4">
              <!-- * App Drawer Subscribers -->
              <template
                v-for="drawerSubscriber in drawerSubscribers"
                :key="drawerSubscriber.label"
              >
                <div
                  :id="
                    drawerSubscriber.target.substring(
                      1,
                      drawerSubscriber.target.length
                    )
                  "
                />
              </template>
              <!-- * Scale -->
              <div v-tooltip.left="scaleStatusMessage">
                <h1
                  class="text-2xl"
                  :class="{
                    'cursor-pointer': !isScalePaired && !isScaleOpened,
                    'text-primary-500/70': isScalePaired,
                    'text-surface-300 dark:text-surface-500': !isScalePaired,
                  }"
                  @click.prevent="connectScale"
                >
                  <UnWeight />
                </h1>
              </div>
              <!-- * Scanner -->
              <div
                v-if="listeners.length > 0"
                v-tooltip.left="
                  `Scan: ${Array.from(new Set(listeners.map((listener) => Array.from(new Set(Object.keys(listener.bindings))).join(',')))).join(',')}`
                "
                class="scale-x-[-1]"
              >
                <h1 class="text-2xl text-primary-500/70">
                  <MdBarcodeReader />
                </h1>
              </div>
            </div>
            <div class="flex flex-row items-center gap-6 sm:flex-col sm:gap-4">
              <AppSettingsButton class="flex h-min w-fit" />
            </div>
          </div>
        </div>
        <div
          v-if="drawerSubscribers.length"
          v-show="showContextDrawer"
          id="context-drawer"
          class="flex w-full min-w-fit md:max-w-lg"
        />
        <div class="flex w-full">
          <RouterView :key="accountVersion" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped></style>
