const masterAccess = ['admin', 'super-user']
const masterUserAccess = [...masterAccess, 'account-owner']
const adminUserAccess = [...masterUserAccess, 'account-admin']
export const PermissionControls = {
  groups: {
    masterAccess,
    masterUserAccess,
    adminUserAccess,
  },
  roles: {
    // Admin
    adminOnly: ['admin'],
    // Account
    account: [...adminUserAccess, 'user'],
    accountManager: [...adminUserAccess],
    deviceManager: [...adminUserAccess],
    accountOwner: [...masterUserAccess],
    universal: [...adminUserAccess, 'user', 'vsf-middleware'],
    // Jobs
    listingControl: [...adminUserAccess, 'listing-control'],
    // ! Temporarily do not allow inheritance, require specific permission.
    storeManager: ['store-manager'],
    productEditor: [...adminUserAccess, 'product-editor'],
    serviceTechnician: [...adminUserAccess, 'service-technician'],
    salesperson: [...adminUserAccess, 'salesperson'],
  },
}
declare module 'vue-router' {
  interface RouteMeta {
    // must be declared by every route
    requiresAuth: boolean
    requiredRoles: (keyof (typeof PermissionControls)['roles'])[]
    requiredGroups: (keyof (typeof PermissionControls)['groups'])[]
  }
}
