import type { ScaleWeightData } from '@/composables/useScale'

import { ref } from 'vue'
import { useThrottleFn } from '@vueuse/core'

import { useScale } from '@/composables/useScale'

const scaleUnits = ref<ScaleWeightData['units']>('lb')
const scaleWeight = ref<ScaleWeightData['value']>(0)
const isReadingScale = ref(false)
const debug = import.meta.env.VITE_ENVIRONMENT === 'local'
const {
  updateScale,
  isScalePaired,
  isScaleOpened,
  requestScale,
  connectScale,
  initiateRead,
  closeScale,
  openScale,
  scaleDevice,
} = useScale(
  (weight) => {
    console.log('SCALE_READING')
    if (weight.state === 'settled') {
      scaleUnits.value = weight.units
      scaleWeight.value = weight.value
    } else {
      throttledScaleReading(weight)
    }
  },
  (event) => {
    // Connected
    debug && console.log('Connected Scale:', event.device.productName)
    open()
  },
  (event) => {
    // Disconnected
    debug && console.log('Disconnected Scale:', event.device.productName)
    reset()
  },
  debug
)
const throttledScaleReading = useThrottleFn((weight: ScaleWeightData) => {
  scaleUnits.value = weight.units
  scaleWeight.value = weight.value
}, 250)
const reset = () => {
  scaleUnits.value = 'lb'
  scaleWeight.value = 0
  isReadingScale.value = false
}
const open = async () => {
  await updateScale()
  if (!isScalePaired.value) {
    if (!isScaleOpened.value) {
      await openScale()
    }
    try {
      await connectScale()
      await initiateRead()
      if (isScaleOpened.value) {
        isReadingScale.value = true
      }
    } catch (err) {
      console.error(err)
      await closeScale()
    }
  } else {
    if (!isScaleOpened.value) {
      await openScale()
    }
    await initiateRead()
    if (isScaleOpened.value) {
      isReadingScale.value = true
    }
    isReadingScale.value = true
  }
}
const close = async () => {
  await closeScale()
  reset()
}
export const useScaleWeight = () => {
  return {
    isScalePaired,
    isScaleOpened,
    scaleUnits,
    scaleWeight,
    isReadingScale,
    requestScale,
    updateScale,
    scaleDevice,
    open,
    close,
  }
}
